import type { MapExpression } from '@trackback/widgets';
import {
  appendContext,
  combineLatestArray,
  combineLatestObject,
} from '../parser';
import type { NamedResolverFunction } from '../types';
import type { Resolvable } from '@trackback/widgets/build/main/expressions/types';

const mapResolver: NamedResolverFunction<
  MapExpression<
    Resolvable<Array<unknown>> | Resolvable<Record<string, unknown>>,
    never
  >
> = (parser, options, onData, [items, template, contextKey = 'item']) =>
  parser.parseArray(
    [items, contextKey] as const,
    {
      error: onData.error,
      next: ([resolvedItems, resolvedContextKey]) => {
        if (!resolvedItems || typeof resolvedItems !== 'object') {
          return onData.next(resolvedItems as never);
        } else if (Array.isArray(resolvedItems)) {
          return combineLatestArray(
            resolvedItems.map(
              (item, index) => onChange =>
                parser.parseWithCallback(
                  template,
                  onChange,
                  appendContext(options, {
                    [resolvedContextKey]: item,
                    [`${resolvedContextKey}-idx`]: index,
                  })
                )
            ),
            onData
          );
        } else {
          const entries = Object.entries(resolvedItems);
          return combineLatestObject(
            entries.map(([key, value]) => ({
              fn: onChange =>
                parser.parseWithCallback(
                  template,
                  onChange,
                  appendContext(options, {
                    [resolvedContextKey]: {
                      key,
                      value,
                    },
                  })
                ),
              key,
            })),
            onData
          );
        }
      },
    },
    options
  );

export default mapResolver;
