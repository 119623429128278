import { LogExpression } from '@trackback/widgets';
import { createNamedExpressionResolver } from '../types';
import { appendContext } from '@app/expressions/parser';

export const logResolver = createNamedExpressionResolver<LogExpression>(
  'log',
  (parser, options, onData, [value, message, contextKey]) => {
    return parser.parseWithCallback(
      [value, contextKey] as const,
      {
        next: ([parsedValue, parsedContextKey]) =>
          parser.parseWithCallback(
            message,
            {
              next: parsedMessage => {
                options.log?.(parsedMessage);
                return onData.next(parsedValue);
              },
              error: onData.error,
            },
            appendContext(options, { [parsedContextKey]: parsedValue })
          ),
        error: onData.error,
      },
      options
    );
  }
);
